.container {
  padding: 45px 100px;
  background-color: #161616;
  color: #ffffff;
  position: relative;
  // z-index: 10111000;

  >.footer-top {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 60px;

    >.footer-left {
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }

    >.footer-right {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px 15px;

      >.footer-right-item {
        display: flex;
        flex-direction: column;

        >h5 {
          font-family: "Kumbh Sans", sans-serif !important;
          font-size: 14px;
          font-weight: 500;
          text-decoration: underline;
          margin-bottom: 15px;
        }

        >label {
          font-size: 14px;
          font-weight: 300;
          margin-bottom: 10px;
          z-index: 1;
          // text-decoration: underline;
          cursor: pointer;

          >a {
            cursor: pointer;
            text-decoration: none;
            color: #ffffff
          }

          &:hover {
            // text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }

  >.footer-bottom {
    p {
      text-align: center;
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 1400px) {
  .container {
    >.footer-top {
      margin-bottom: 15px;

      >.footer-left {
        // width: 280px;
        margin-right: 15px;
        width: fit-content;

        >img {
          width: 120px;
        }
      }

      >.footer-right {
        >.footer-right-item {
          >h5 {}

          >label {}
        }
      }
    }

    >.footer-bottom {
      p {}
    }
  }
}

@media screen and (max-width: 1200px) {
  .container {
    >.footer-top {
      >.footer-left {
        >img {}
      }

      >.footer-right {
        >.footer-right-item {
          >h5 {
            font-size: 12px;
          }

          >label {
            font-size: 12px;
          }
        }
      }
    }

    >.footer-bottom {
      p {
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 992px) {}

@media screen and (max-width: 768px) {
  .container {
    padding: 30px 30px 15px 30px;

    >.footer-top {
      >.footer-left {
        >img {}
      }

      >.footer-right {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px 15px;

        >.footer-right-item {
          >h5 {
            font-size: 12px;
          }

          >label {
            font-size: 12px;
          }
        }
      }
    }

    >.footer-bottom {
      p {}
    }
  }
}

@media screen and (max-width: 576px) {
  .container {
    >.footer-top {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      >.footer-left {
        margin-bottom: 15px;

        >img {}
      }

      >.footer-right {
        >.footer-right-item {
          >h5 {}

          >label {}
        }
      }
    }

    >.footer-bottom {
      p {}
    }
  }
}

@media screen and (max-width: 425px) {}