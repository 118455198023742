.container {
  padding: 100px 15px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 42px;
    text-align: center;
  }

  p {
    font-size: 16px;
    text-align: center;
    color: #77767f;
    width: 800px;
    margin-bottom: 45px;
  }
  button {
    margin-top: 90px;
    background-color: transparent;
    color: #161616;
    outline: none;
    border: 1px solid #161616;
    width: 210px;
    padding: 8px 0px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    z-index: 1;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 0;
      z-index: -1;
      height: 100%;
      width: 0px;
      background-color: #161616;
      transition: 400ms all;
    }

    &:hover {
      transition: 600ms;
      color: #ffffff;
    }

    &:hover::after {
      left: 0;
      width: 100%;
    }
  }

  .services-tabs-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 45px;

    label {
      margin-right: 45px;
      color: #77767f;
      cursor: pointer;
      z-index: 1;
      transition: 300ms;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        position: relative;
        color: #161616;

        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 0;
          height: 1px;
          background-color: #161616;
          animation-name: active-tab-anim;
          animation-duration: 300ms;
          animation-timing-function: ease-in;
          animation-delay: 0;
          animation-iteration-count: 1;
          animation-play-state: running;
          animation-fill-mode: forwards;
        }
      }
    }

    .active-tab {
      color: #161616;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 1px;
        background-color: #161616;
        animation-name: active-tab-anim;
        animation-duration: 300ms;
        animation-timing-function: ease-in;
        animation-delay: 0;
        animation-iteration-count: 1;
        animation-play-state: running;
        animation-fill-mode: forwards;
      }

      @keyframes active-tab-anim {
        100% {
          width: 100%;
        }
      }
    }
  }

  .active-service-tab-container {
    h1 {
      font-weight: 500;
      text-align: center;
      margin-bottom: 45px;
    }

    .services-card-container {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .service-card {
        // margin-right: 60px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.box {
  box-shadow: -2px -2px 10px rgba(0, 0, 0, 0.4);
}

.services-imagery {
  margin: 0px auto;
  padding: 30px 0;
  width: 950px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 350px;
  gap: 10px;
  justify-content: center;

  .grid-item {
    place-items: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 1200px) {
  .services-imagery {
    width: 720px;
  }
  .container {
    button {
      font-style: 14px;
    }
  }
}

@media screen and (max-width: 992px) {
  .container {
    padding: 100px 15px;

    h1 {
    }

    .services-tabs-buttons {
      margin-bottom: 20px;

      label {
        margin-right: 15px;

        &:last-child {
        }

        &:hover {
          &::after {
          }
        }
      }

      .active-tab {
        &::after {
        }
      }
    }

    .active-service-tab-container {
      h1 {
        font-size: 24px;
        margin-bottom: 15px;
      }

      .services-card-container {
        flex-direction: column;

        .service-card {
          margin-right: 0;
          margin-bottom: 45px;

          &:last-child {
          }
        }
      }
    }
  }

  .services-imagery {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .container {
    h1 {
      font-size: 24px;
      margin-bottom: 15px;
    }

    p {
      font-size: 12px;
    }

    .services-tabs-buttons {
      label {
        font-size: 12px;
      }
    }
  }

  .active-service-tab-container {
    h1 {
      font-size: 18px;
      margin-bottom: 15px;
    }
  }

  .services-imagery {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 420px;
  }
}

@media screen and (min-width: 240px) and (max-width: 576px) {
  .container {
    h1 {
      font-size: 20px;
    }

    p {
      // width: 90%;
      font-size: 12px;
    }

    .services-tabs-buttons {
      label {
        font-size: 12px;
      }
    }
  }

  .services-imagery {
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: 420px;
  }
}

@media screen and (max-width: 425px) {
}
