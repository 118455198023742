.container {
  width: 520px;
  // min-height: 240px;
  padding: 30px 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  transition: all 300ms;
  cursor: pointer;
  &:hover {
    background-color: #eee;
    transform: scale(1.1);
  }

  h3 {
    font-size: 20px;
    font-weight: 400;
    width: 100%;
    text-align: center;
  }

  p {
    font-size: 16px;
    font-weight: 300;
    color: #77767f;
  }

  label {
    width: 100%;
    text-align: center;
    font-size: 16px;
  }

  .service-tab-para {
    margin-top: 5px;
    margin-bottom: 15px;
    font-size: 14px;
    width: 100%;
  }
  .service-tab-rate {
  }
}

@media screen and (max-width: 1400px) {
}

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 992px) {
  .container {
    // margin-bottom: 30px;
    // width: 70%;
    h3 {
    }

    p {
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    margin: 0 auto;
    width: 420px;
    // margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      font-size: 16px;
    }

    p {
      font-size: 12px;
    }

    label {
      font-size: 12px;
    }
    .service-tab-para {
      font-size: 12px;
    }
  }
}

@media screen and (min-width: 240px) and (max-width: 576px) {
  .container {
    margin: 0 auto;
    width: 90%;
    // margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      font-size: 16px;
    }

    p {
      font-size: 12px;
    }

    label {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 425px) {
}
