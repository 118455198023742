.container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;

  min-height: 90vh;
  // border: 1px solid #161616;

  .left {
    padding: 60px 30px;
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1,
    label {
      color: #161616;
    }
    h1 {
      padding-bottom: 30px;
    }
    p {
      margin-top: 15px;

      margin-bottom: 90px;
      text-align: center;
      color: #161616;
    }

    .para {
      color: #a7a6ad;
      position: relative;
      z-index: 2;
      font-style: italic;
      margin-bottom: 60px;

      &::after {
        content: "";
        position: absolute;
        left: -15px;
        top: -30px;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background-color: #161616;
        z-index: -1;
      }
    }

    h1 {
      text-align: center;
      font-size: 32px;
      font-weight: 700;
    }

    label {
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 30px;
    }

    a {
      background-color: transparent;
      color: #161616;
      text-decoration: none;
      outline: none;
      border: 1px solid #161616;
      width: 270px;
      padding: 8px 0px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      z-index: 1;
      display: block;
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 0;
        z-index: -1;
        height: 100%;
        width: 0px;
        background-color: #161616;
        transition: 400ms all;
      }

      &:hover {
        transition: 600ms;
        color: #ffffff;
      }

      &:hover::after {
        left: 0;
        width: 100%;
      }
    }
  }

  .right {
    // border-right: 1px solid #161616;
    // background-color: #d1d1d1;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      font-size: 14px;
      color: #161616;
      margin-bottom: 15px;
      padding: 0 15px;
      text-align: center;
      font-size: 12px;
      text-transform: uppercase;
    }

    .img-box {
      width: 380px;
      height: 520px;

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        // border: 1.5px solid #161616;
        transition: 500ms;

        &:hover {
          z-index: 5;
          transform: scale(1.3) translateY(0px);
        }
      }
    }

    label {
      margin-top: 25px;
      font-size: 14px;
      text-transform: capitalize;
      text-decoration: underline;
      &:hover {
        color: #686868;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
}

@media screen and (max-width: 1200px) {
  .container {
    .right {
      .img-box {
        width: 285px;
        height: 390px;

        video {
          &:hover {
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .container {
    h3 {
      margin-bottom: 15px;
    }

    p {
      margin-bottom: 15px;
    }

    .service-tab-divider {
      &::after {
        left: calc(50% - 40px);
        width: 80px;
        bottom: -15px;
        height: 2px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .right {
      p {
        padding: 0 8px;
      }
      .img-box {
        width: 190px;
        height: 260px;

        video {
          &:hover {
          }
        }
      }
    }
  }
}

@media screen and (min-width: 240px) and (max-width: 576px) {
  .container {
    flex-direction: column;

    .left {
      width: 100%;
    }
    .right {
      width: 100%;
      padding: 30px 0;
    }
  }
}
