.container {
    // background-color: #161616;

    .logo-container {
        display: flex;
        justify-content: center;
        padding: 16px 0;
        position: relative;
        transition: 800ms;
    }

    .logo-container-scrolled {
        display: flex;
        justify-content: center;
        padding: 0px 0;
        position: relative;
        transition: 800ms;
        background-color: #fff;
    }

    nav {
        position: relative;
        background-color: #16161690;
        border: 1px solid #eee;
        transition: 800ms;

        ul {
            list-style: none;
            display: flex;
            justify-content: center;

            li {
                padding: 8px 12px;
                cursor: pointer;
                font-weight: 300;
                font-size: 16px;
                transition: 0.5s;
                color: #fff;

                &:hover {
                    background-color: #b7b7b7;
                }
            }
        }

        >.shop-link {
            position: absolute;
            right: 15px;
            top: 0;
            display: block;
            padding: 8px;
            cursor: pointer;
            font-weight: 300;
            font-size: 16px;
            transition: 0.5s;
            color: #fff;
            cursor: pointer;

            &:hover {
                background-color: #b7b7b7;
            }
        }
    }

    >.show-side-menu-scrolled {
        background-color: #161616;
    }
}

.hamburger-container {

    z-index: 1000;
    position: absolute;
    right: 30px;
    display: flex;
    justify-content: center;
    align-self: center;
    display: none;
    transform: scale(1.6);
    overflow: hidden;

    >img {
        margin-top: 5px;
    }
}

@media screen and (max-width: 1200px) {
    .container {
        nav {
            ul {
                li {
                    font-size: 14px;
                }
            }

            >.shop-link {
                font-size: 14px;
            }
        }
    }
}

@media screen and (max-width: 992px) {}

@media screen and (max-width: 768px) {
    .container {

        background-color: #fff;

        .logo-container {

            padding: 3px 0;

            >img {
                height: 35px;
            }
        }



        nav {
            position: absolute;
            right: -100%;
            height: calc(100vh - 48px);
            z-index: 1111111;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding-top: 15px;
            transition: 500ms;

            ul {
                flex-direction: column;

                li {
                    width: 100%;
                    padding: 7px 70px;
                    font-size: 12px;
                    text-align: center;
                }
            }

            >.shop-link {
                width: 100%;
                padding: 5px 70px;
                position: unset;
                font-size: 12px;
                text-align: center;
            }
        }

        >.show-side-menu {
            right: 0;
        }
    }

    .hamburger-container {
        display: block;
    }
}