.container {
  display: flex;
  justify-content: center;
  min-height: 90vh;

  .left {
    padding: 60px 30px;
    width: 45%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    h1,
    label {
      color: #161616;
    }

    p {
      margin-top: 15px;
      margin-bottom: 30px;
      text-align: center;
      color: #161616;
    }

    .para {
      color: #a7a6ad;
      position: relative;
      z-index: 2;
      font-style: italic;

      &::after {
        content: "";
        position: absolute;
        right: -30px;
        top: -30px;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background-color: #161616;
        z-index: -1;
      }
    }

    h1 {
      text-align: center;
      font-size: 32px;
      font-weight: 700;
    }

    label {
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 30px;
    }

    button {
      background-color: transparent;
      color: #161616;
      outline: none;
      border: 1px solid #161616;
      width: 270px;
      padding: 8px 0px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      z-index: 1;

      &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 0;
        z-index: -1;
        height: 100%;
        width: 0px;
        background-color: #161616;
        transition: 400ms all;
      }

      &:hover {
        transition: 600ms;
        color: #ffffff;
      }

      &:hover::after {
        left: 0;
        width: 100%;
      }
    }
  }

  .right {
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;

    .img-box {
      width: 170px;
      height: 240px;
      position: relative;
      z-index: 1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        transform: translate(0%, -20%);
        border: 1.5px solid #161616;
        transition: 500ms;

        &:hover {
          z-index: 5;
          transform: scale(1.1) translateY(-50px);
        }

        &:nth-child(1) {
          left: -50%;
          top: 50%;
          z-index: 3;
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
}

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 992px) {
  .container {
    padding-bottom: 30px;

    h3 {
      margin-bottom: 15px;
    }

    p {
      margin-bottom: 15px;
    }

    .service-tab-divider {
      &::after {
        left: calc(50% - 40px);
        width: 80px;
        bottom: -15px;
        height: 2px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding-bottom: 100px;
    .left {
      padding: 60px 0;
      width: 80vw;
    }

    .right {
      .img-box {
        transform: translateX(25%);
      }
    }
  }
}

@media screen and (min-width: 240px) and (max-width: 576px) {
  .container {
    width: 90%;
    margin: 0 auto;

    h3 {
      font-size: 16px;
    }

    p {
      font-size: 12px;
    }

    label {
      font-size: 12px;
    }
  }
}
