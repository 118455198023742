.container {
  padding: 60px 0;
  background-color: #e2e2e2;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 52px;
    text-align: center;
    color: #161616;
    margin-bottom: 15px;
  }

  p {
    text-align: center;

    font-size: 16px;
    color: #161616;
    margin-bottom: 60px;
  }

  .send-email-group {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      width: 160px;
      padding: 7px 12px;
      font-size: 16px;
      outline: none;
      border: none;
      background-color: #161616;
      color: #e2e2e2;
      z-index: 1;

      &:hover {
        transition: 200ms;
        box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.63);
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .container {
    h1 {
      font-size: 42px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .container {
    h1 {
      font-size: 32px;
    }

    p {
      font-size: 14px;
      margin-bottom: 30px;
    }

    .send-email-group {
      button {
        width: 140px;
        padding: 6px 12px;
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 30px 0;

    h1 {
      font-size: 28px;
    }

    p {
      font-size: 12px;
    }

    .send-email-group {
      button {
        width: 140px;
        padding: 6px 12px;
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
}

@media screen and (min-width: 240px) and (max-width: 576px) {
  .container {
    .send-email-group {
      button {
        width: 100%;
      }
    }
  }
}
