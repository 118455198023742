.container {
  padding: 120px 0;
  background-color: #161616;
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h1 {
    font-size: 32px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 15px;
  }

  p {
    text-align: center;
    width: 1000px;
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 60px;
    font-weight: 200;
  }

  .send-email-group {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      width: 160px;
      padding: 7px 12px;
      font-size: 16px;
      outline: none;
      border: none;
      background-color: #ffffff;
      color: #161616;
      z-index: 1;

      &:hover {
        transition: 200ms;
        box-shadow: 1px 1px 15px rgba(71, 71, 71, 0.63);
      }
    }
  }
}

// @media screen and (max-width: 1400px) {
//     .container {
//         h1 {
//             font-size: 42px;
//         }
//     }
// }

@media screen and (max-width: 1200px) {
  .container {
    p {
      width: 800px;
      font-size: 14px;
      margin-bottom: 30px;
    }

    .send-email-group {
      button {
        width: 140px;
        padding: 6px 12px;
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 30px 0;

    h1 {
      font-size: 24px;
    }

    p {
      width: 600px;
      font-size: 12px;
    }

    .send-email-group {
      button {
        width: 140px;
        padding: 6px 12px;
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
}

@media screen and (min-width: 240px) and (max-width: 576px) {
  .container {
    h1 {
      font-size: 20px;
    }

    p {
      width: 90%;
    }

    .send-email-group {
      button {
        width: 100%;
      }
    }
  }
}
