.classes-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #161616;

  > h1 {
    color: #ffffff;
  }
  > p {
    color: #ffffff;
    text-align: center;
    margin-top: 20px;
  }
}
