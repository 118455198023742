.container {
  display: flex;
  overflow: hidden;
  height: 100vh;
  background-color: #fff;

  .model-left-container {
    width: 50%;
    position: relative;

    .model-image {
      height: 100%;
      width: 100%;
      object-fit: cover;
      position: absolute;
    }
  }

  .model-right-container {
    width: 50%;
  }

  .meraki-welcome-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      color: #77767f;
      text-align: center;
    }

    h1 {
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 30px;
      width: 420px;
      text-align: center;
      color: #161616;
    }

    p {
      margin-bottom: 30px;
      padding: 0 70px;
    }

    button {
      background-color: transparent;
      color: #161616;

      outline: none;
      border: none;
      border: 1px solid #161616;
      outline: none;
      width: 270px;
      padding: 8px 0px;
      cursor: pointer;
      z-index: 1;
      position: relative;
      overflow: hidden;

      &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 0;
        z-index: -1;
        height: 100%;
        width: 0px;
        background-color: #161616;
        transition: 400ms all;
      }

      &:hover {
        transition: 600ms;
        color: #ffffff;
      }

      &:hover::after {
        left: 0;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
}

@media screen and (max-width: 1200px) {
  .container {
    .model-left-container {
      .model-image {
      }
    }

    .meraki-welcome-container {
      h1 {
        font-size: 38px;
        line-height: 48px;
        margin-bottom: 10px;
        width: 320px;
      }

      p {
        margin-bottom: 15px;
      }

      button {
        z-index: 1;
      }
    }
  }
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
  .container {
    height: 100vh;
    flex-direction: column-reverse;
    position: relative;

    .model-left-container {
      width: 100%;
      height: 100vh;
      position: absolute;

      z-index: 11;

      .model-image {
        // top: 50px;
        height: 100%;
        object-fit: cover;
      }
    }

    .model-right-container {
      width: 100%;
      height: 100vh;
      position: absolute;
      z-index: 111;
      background-color: #000000a2;
    }

    .meraki-welcome-container {
      h1,
      p {
        color: #ffffff;
      }

      h1 {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 10px;
        width: 270px;
      }

      p {
        font-size: 12px;
        margin-bottom: 15px;
      }

      button {
        color: #ffffff;

        border: 1px solid #ffffff;

        font-style: 10px;

        &::after {
          content: "";
          position: absolute;
          left: 50%;
          top: 0;
          z-index: -1;
          height: 100%;
          width: 0px;
          background-color: #ffffff;
          transition: 400ms all;
        }

        &:hover {
          transition: 600ms;
          color: #161616;
        }

        &:hover::after {
          left: 0;
          width: 100%;
        }
      }
    }
  }
}

@media screen and (min-width: 576px) {
}

@media screen and (max-width: 425px) {
}
