.container {
    width: 100%;
    overflow: hidden;
}

.header-container {
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
}