.container {
  padding: 100px 0;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;

  .founder-container {
    width: 1200px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    padding-top: 60px;

    .founder-left {
      width: 25%;
      height: 420px;
      position: relative;
      h1 {
        display: flex;
        width: max-content;
        position: absolute;
        left: -210%;
        top: -40px;
        font-size: 62px;
        text-align: center;
        font-weight: 100;
        word-spacing: 10px;
        letter-spacing: 6px;
        // div {
        //   display: inline-block;
        span {
          font-weight: 600;
          font-style: italic;
          margin-left: 10px;
        }
        // }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .founder-right {
      width: 60%;
    }

    .founder-right {
      padding-left: 30px;

      p {
        color: #77767f;
        margin-bottom: 50px;
      }

      span {
        font-family: "Cinzel", "Times New Roman", Times, serif;
        color: #77767f;
        font-size: 18px;

        h3 {
          color: black;
          font-size: 20px;
          margin-bottom: 30px;
        }
      }

      h2 {
        font-size: 28px;
      }

      h6 {
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .container {
    padding: 60px 0;

    .founder-container {
      width: 980px;
      justify-content: space-between;
      .founder-left {
        width: 25%;
        margin-bottom: 30px;

        h1 {
          left: -260%;
        }
      }

      .founder-right {
        width: 70%;

        p {
          margin-bottom: 15px;
        }

        h2 {
          font-size: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .container {
    display: flex;
    justify-content: center;
    h1 {
      font-size: 24px;
    }

    .founder-container {
      width: 90%;

      .founder-left {
        width: 35%;
        h1 {
          left: -130%;
          top: -30px;
          font-size: 42px;
          text-align: center;
          font-weight: 100;
          word-spacing: 7px;
          letter-spacing: 3px;
        }
        img {
        }
      }

      .founder-right {
        p {
        }

        span {
          h3 {
          }
        }

        h2 {
          font-size: 18px;
        }

        h6 {
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .container {
    .founder-container {
      width: 100%;
      flex-direction: column;

      .founder-left {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;

        h1 {
          position: unset;
          margin-bottom: 15px;
          font-size: 32px;
        }

        img {
          object-fit: unset;
          width: 240px;
          height: unset;
        }
      }

      .founder-right {
        width: 90%;
        padding: 15px;
      }
    }
  }
}
