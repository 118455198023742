.container {
  padding: 60px 30px;
  display: flex;
  // justify-content: ;
  color: #161616;

  .left {
    margin-right: 260px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      right: -230px;
      top: 50%;
      height: 1px;
      width: 200px;
      background-color: #161616;
    }

    &::before {
      content: "";
      position: absolute;
      left: -10%;
      top: -30%;
      height: 200px;
      width: 200px;
      border-radius: 50%;
      background-color: #e2e2e2;
      z-index: -1;
    }

    h1 {
      font-size: 82px;
      font-weight: 400;
    }

    p {
      font-size: 14px;
      text-transform: uppercase;
    }
  }

  .right {
    height: 130px;
    width: 130px;
    border-radius: 50%;
    background-color: #e2e2e2;

    button {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow: hidden;
      z-index: 3;
      transition: 300ms all;
      outline: none;
      border: 0;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 100%;
        background-color: #161616;
        z-index: -1;
        transition: 300ms all;
      }

      &:hover {
        color: #e2e2e2;

        &::after {
          top: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
}

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 992px) {
  .container {
    .left {
      h1 {
        font-size: 56px;
      }
    }
    .right {
      button {
        aspect-ratio: 1;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    .left {
      margin: 0;
      h1 {
        font-size: 48px;
        width: max-content;
      }

      p {
        width: 100%;
      }
      &::after {
        content: "";
        height: 0;
      }
      &::before {
        content: "";
        height: 200px;
        width: 200px;
        top: -80%;
        left: -25%;
      }
    }

    .right {
      margin-left: auto;
      margin-top: 25px;
    }
  }
}

@media screen and (min-width: 240px) and (max-width: 576px) {
}

@media screen and (max-width: 425px) {
  .container {
    flex-direction: column;
    .left {
      margin: 0;
      h1 {
        font-size: 48px;
        width: max-content;
      }

      p {
        width: 100%;
      }
      &::after {
        content: "";
        height: 0;
      }
      &::before {
        content: "";
        height: 200px;
        width: 200px;
        top: -80%;
        left: -25%;
      }
    }
  }
}
